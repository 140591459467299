import * as React from "react";
import clsx from "clsx";

import "./loader.scss";

const Loader = ({ color = "blue" }) => (
  <div
    className={clsx("lds-ring", {
      ["lds-ring_blue"]: color === "blue",
      ["lds-ring_white"]: color === "white",
    })}
  >
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export default Loader;
