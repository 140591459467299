import * as React from "react";
import logo from "../../assets/images/logo.png";

const Header = () => {
  return (
    <div className="header-wrapper">
      <div className="header">
        <img src={logo} alt="" className="logo" />
      </div>
    </div>
  );
};

export default Header;
